<script setup>
import { inject } from 'vue';

// Components
import Icon from '@/Components/Icon.vue';

// Icons
import calendar from '@i/calendar-pink.svg';
import clock from '@i/clock-pink.svg';
import location from '@i/location-pink.svg';

const props = defineProps({
    shift: Object,
});

const dayjs = inject('dayjs');
</script>

<template>
    <div class="p-4 mb-6 rounded-lg md:mb-6 md:px-6 md:py-8 bg-blue-medium" v-if="shift">
        <div class="w-full">
            <ul>
                <li class="flex flex-col gap-3 leading-4 text-white" v-if="shift.start && shift.end">
                    <div class="flex items-center gap-2">
                        <Icon :icon="calendar" />

                        <span class="text-sm md:text-base">
                            {{ dayjs(shift.start).format('dddd DD MMMM YYYY') }}
                        </span>
                    </div>

                    <div class="flex items-center gap-2">
                        <Icon :icon="clock" />

                        <span class="text-sm md:text-base">
                            {{
                                dayjs(shift.start).format('HH:mm') +
                                ' - ' +
                                dayjs(shift.end).format('HH:mm') +
                                ' (' +
                                $t('Break ') +
                                Math.floor(shift.break / 3600) +
                                ':' +
                                Math.floor(shift.break / 60) +
                                ') '
                            }}
                        </span>
                    </div>

                    <div class="flex items-center gap-2">
                        <Icon :icon="location" />

                        <span class="text-sm md:text-base">
                            {{ shift.location.name }} {{ shift.location.address.city }}</span
                        >
                    </div>
                </li>
            </ul>
        </div>
    </div>
</template>